import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@core/cache/cache.service';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import { BehaviorSubject, catchError, firstValueFrom, Observable, of, tap } from 'rxjs';
import { Configuration } from './configuration.types';


/**
 * Service for managing configuration for a merchant.
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    /**
     * The current merchant.
     */
    private _merchant: Merchant;

    /**
     * The current configuration.
     */
    private _configuration: BehaviorSubject<Configuration | null> = new BehaviorSubject(null);

    /**
     * The cache namespace.
     */
    private _cacheNamespace: string = 'configuration';

    /**
     * Creates an instance of ConfigurationService.
     * @param {HttpClient} _httpClient - The HttpClient service to make HTTP requests.
     * @param {MerchantService} _merchantService - The MerchantService to get the merchant information.
     * @param {CacheService} _cacheService - The CacheService to cache the configuration.
     */
    constructor(
        private _httpClient: HttpClient,
        private _merchantService: MerchantService,
        private _cacheService: CacheService,
    ) {
        // Get the merchant
        this._merchantService.merchant$
            .subscribe((merchant: Merchant) => {
                const reload = this._merchant?._id.toString() !== merchant?._id.toString();
                this._merchant = merchant;
                if (reload) {
                    this._configuration.next(null);
                    this.getConfiguration().subscribe();
                }
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns an observable that emits the current configuration.
     * @returns An observable that emits the current configuration.
     */
    get configuration$(): Observable<Configuration> {
        return this._configuration.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Gets the current configuration.
     * @param options - The options related to cache and propagation.
     * @returns The current configuration.
     */
    getConfiguration(options: HttpOptions = DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Configuration> {
        // If there is no merchant, return null
        if (!this._merchant) {
            console.log('Error: No merchant.');
            return of(null);
        }
        // If the configuration are already loaded, return them
        const cacheParams: any = { merchantId: this._merchant._id };
        if (options.cache && this._cacheService.has(this._cacheNamespace, 'configuration', cacheParams)) {
            const configuration: Configuration = this._cacheService.get(this._cacheNamespace, 'configuration', cacheParams);
            if (options.forcePropagate) {
                this._configuration.next(configuration);
            }
            return of(configuration);
        }
        // Otherwise, load the configuration from the backend
        return this._httpClient.get<Configuration>(
            `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/configurations`,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                withCredentials: true
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.error(`Error: ${error}`);
                    return of(null);
                }),
                // Handle the logic of the cache and the propagation
                tap((configuration: Configuration) => {
                    if (options.cache) {
                        this._cacheService.set(this._cacheNamespace, 'configuration', cacheParams, configuration, options.expire);
                    }
                    if (options.propagate) {
                        this._configuration.next(configuration);
                    }
                })
            );
    }
}